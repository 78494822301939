import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  CircularProgress,
  Box,
  Button 
} from "@mui/material";
import Confetti from "react-confetti";
import "./Quiz.css";
import QuizComponent from "./QuizComponent";
import StudentLeaderboard from "./Leaderboard";
import { useNavigate } from 'react-router-dom'; 

const PodiumPlatform: React.FC<{ position: number }> = ({ position }) => (
  <Box
    sx={{
      width: 220,
      height: position === 1 ? 400 : 300,
      backgroundColor: "grey.300",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: -10,
      marginX: 1,
      position: "relative",
    }}
  >
    <Typography variant="h3" sx={{ color: "black", fontWeight: "bold", marginBottom: 1 }}>
      {position}
    </Typography>

    <Typography variant="body1" sx={{ color: "gray", fontWeight: "bold", textAlign: "center" }}>
      {position === 1 ? "13 Questions Answered Correct" : position === 2 ? "10 Questions Answered Correct" : "09 Questions Answered Correct"}
    </Typography>

    <Box
      component="img"
      src={
        position === 1
          ? "https://firebasestorage.googleapis.com/v0/b/classess-501e6.appspot.com/o/images%2Fimages%20(6).jfif?alt=media&token=debbe6e2-26a4-41e1-9cc5-ccde771eb6a2"
          : position === 2
          ? "https://firebasestorage.googleapis.com/v0/b/classess-501e6.appspot.com/o/images%2Fishita-niwas-gn-ii.webp?alt=media&token=52a5e607-a44a-41ae-8c12-d40bc2c7bcb2"
          : "https://firebasestorage.googleapis.com/v0/b/classess-501e6.appspot.com/o/images%2Fdownload%20(5).jfif?alt=media&token=6dc01496-1c8e-44fc-81a4-ec7688085a9b"
      }
      alt={`Position ${position}`}
      sx={{ width: 100, height: 100, borderRadius: "50%", border: "2px solid grey", objectFit: "cover", marginTop: -32 }}
    />

    <Typography variant="h6" sx={{ color: "black", fontWeight: "bold", textAlign: "center", marginTop: 1 }}>
      {position === 1 ? "Abu" : position === 2 ? "Suhasini" : "Jyo"}
    </Typography>
  </Box>
);

const Podium: React.FC = () => {
  const [showConfetti, setShowConfetti] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => setShowConfetti(false), 5000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {showConfetti && <Confetti />}
      <Box sx={{ display: "flex", alignItems: "flex-end", justifyContent: "center", height: "auto", position: "relative", paddingTop: 20 }}>
        <PodiumPlatform position={2} />
        <PodiumPlatform position={1} />
        <PodiumPlatform position={3} />
      </Box>
    </>
  );
};

const Quiz: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [showPodium, setShowPodium] = useState(false);
  const navigate = useNavigate(); 


  useEffect(() => {
    const timer = setTimeout(() => setLoading(false), 1000);
    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return (
      <Container maxWidth="md" style={{ textAlign: "center", marginTop: "200px" }}>
        <CircularProgress />
        <Typography variant="h6" style={{fontSize:"20px", marginTop: "40px" , color:"purple"}}>Loading quiz...</Typography>
      </Container>
    );
  }

  return (
    <Box
      sx={{
        height: "calc(100vh - 52px)",
        overflowY: "auto",
        padding: 5,
        margin: 5,
        marginBottom: "48px",
        scrollbarWidth: "none",
        "&::-webkit-scrollbar": { display: "none" },
      }}
    >
       <Button
        color="secondary"
        onClick={() => navigate(-1)}
        style={{ position: 'fixed', bottom: 40, right: 40, zIndex: 1000 }}
      >
        End Quiz
      </Button>

      {!showPodium && (
        <QuizComponent
          onShowPodium={() => setShowPodium(true)}
        />
      )}
      {showPodium && <StudentLeaderboard />}
    </Box>
  );
};

export default Quiz;
