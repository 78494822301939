/* eslint-disable @typescript-eslint/no-unused-vars */
// @ts-nocheck
import React, { useState, useEffect, useRef } from "react";
import {
  Drawer,
  Box,
  Typography,
  Button,
  TextField,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Paper,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import { InputAdornment, Divider } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ChatIcon from "@mui/icons-material/Chat";
import SummarizeIcon from "@mui/icons-material/Summarize";
import MicIcon from "@mui/icons-material/Mic";
import SendIcon from "@mui/icons-material/Send";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AIASSISTAPI from '../http/aiassistantapi';

// unused
const CopilotUI1 = (props) => {
  return (
    <Box
      sx={{
        width: "350px",
        margin: "auto",
        border: "1px solid #e0e0e0",
        borderRadius: "12px",
        overflow: "hidden",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        backgroundColor: "#fff",
      }}
    >
      {/* Header */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "10px",
          backgroundColor: "#F5F5F5",
        }}
      >
        <IconButton onClick={props.onClose}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="body1" sx={{ fontWeight: "600" }}>
          Vidya
        </Typography>
        <IconButton>
          <ChatIcon />
        </IconButton>
      </Box>

      {/* Main content */}
      <Box sx={{ padding: "20px", textAlign: "center" }}>
        <img
          src1="https://classess.com/img/Classess.png"
          src="assets/VidyaLogo.gif"
          alt="Copilot Logo"
          style={{ height: "96px", marginBottom: "15px" }}
        />
        {/* <Typography variant="h6" sx={{ fontWeight: '600' }}>
                    Vidya
                </Typography> */}
        <Typography
          variant="body2"
          sx={{ color: "gray", marginBottom: "20px" }}
        >
          Your everyday AI companion
        </Typography>
        {/* 
                <Typography variant="body2" sx={{ marginBottom: '10px' }}>
                    Hi, see what's possible with Copilot in Edge
                </Typography> */}

        <Button
          variant="outlined"
          fullWidth
          startIcon={<SummarizeIcon />}
          sx={{
            marginBottom: "10px",
            borderRadius: "8px",
            textTransform: "none",
          }}
        >
          Generate page summary
        </Button>
        <Button
          variant="outlined"
          fullWidth
          startIcon={<SummarizeIcon />}
          sx={{ borderRadius: "8px", textTransform: "none" }}
        >
          Suggest questions about this page
        </Button>

        <Divider sx={{ margin: "20px 0" }} />
        {/* 
                <Button startIcon={<AccountCircleIcon />} sx={{ textTransform: 'none' }}>
                    Sign in to ask more questions and have longer conversations
                </Button> */}
      </Box>

      {/* Footer */}
      <Box
        sx={{
          padding: "10px",
          backgroundColor: "#F5F5F5",
        }}
      >
        <TextField
          fullWidth
          placeholder='Ask me anything or type "@"'
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <ChatIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton>
                  <MicIcon />
                </IconButton>
                <IconButton>
                  <SendIcon />
                </IconButton>
              </InputAdornment>
            ),
            sx: { borderRadius: "50px", backgroundColor: "#fff" },
          }}
        />
      </Box>
    </Box>
  );
};

// clean (no functionality)
const CopilotUI2 = (props) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        border: "1px solid #e0e0e0",
        borderRadius: "12px",
        overflow: "hidden",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        backgroundColor: "#fff",
      }}
    >
      {/* Header */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "10px",
          backgroundColor: "#F5F5F5",
        }}
      >
        <IconButton onClick={props.onClose}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="body1" sx={{ fontWeight: "600" }}>
          Vidya
        </Typography>
        <IconButton>
          <ChatIcon />
        </IconButton>
      </Box>

      {/* Main content */}
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          padding: "20px",
          overflowY: "auto",
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src="assets/VidyaLogo.gif"
            alt="Copilot Logo"
            style={{ height: "96px", marginBottom: "15px" }}
          />
          <Typography
            variant="body2"
            sx={{ color: "gray", marginBottom: "20px" }}
          >
            Your everyday AI companion
          </Typography>

          <Button
            variant="outlined"
            fullWidth
            startIcon={<SummarizeIcon />}
            sx={{
              marginBottom: "10px",
              borderRadius: "8px",
              textTransform: "none",
            }}
          >
            Generate page summary
          </Button>
          <Button
            variant="outlined"
            fullWidth
            startIcon={<SummarizeIcon />}
            sx={{ borderRadius: "8px", textTransform: "none" }}
          >
            Suggest questions about this page
          </Button>
        </Box>
      </Box>

      {/* Footer */}
      <Box
        sx={{
          padding: "10px",
          backgroundColor: "#F5F5F5",
        }}
      >
        <TextField
          fullWidth
          placeholder='Ask me anything or type "@"'
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <ChatIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton>
                  <MicIcon />
                </IconButton>
                <IconButton>
                  <SendIcon />
                </IconButton>
              </InputAdornment>
            ),
            sx: { borderRadius: "50px", backgroundColor: "#fff" },
          }}
        />
      </Box>
    </Box>
  );
};

const CopilotUI = (props) => {
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [chapId, setChapId] = useState("");
  const [chapName, setChapName] = useState("");
  const [lessonPlan, setLessonPlan] = useState("");
  const { plan, state } = props;

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    // messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  // CORRECT FUNCTION
  function findNestedValue(obj, key) {
    // Check if obj is valid (not null or undefined)
    if (obj === null || obj === undefined) {
      return undefined;
    }

    // Handle case where obj is an array
    if (Array.isArray(obj)) {
      for (let i = 0; i < obj.length; i++) {
        const result = findNestedValue(obj[i], key);
        if (result !== undefined) {
          return result;
        }
      }
    }

    // If obj is an array with key-value pairs, find the matching key
    if (Array.isArray(obj) && obj.length === 2 && typeof obj[0] === 'string' && obj[0] === key) {
      return obj[1];
    }

    // If obj is an object, iterate through its keys
    if (typeof obj === 'object' && obj !== null) {
      for (let prop in obj) {
        if (obj.hasOwnProperty(prop)) {
          const result = findNestedValue(obj[prop], key);
          if (result !== undefined) {
            return result;
          }
        }
      }
    }

    return undefined;
  }

  // Assuming jsonData is your JSON object
  // const contentValue = findNestedValue(jsonData, "content");

  // console.log(contentValue);


  // UNUSED
  function extractOpenAIContent(jsonString) {
    try {
      const data = JSON.parse(jsonString);

      // Navigate through the specific structure
      if (data &&
        data.data &&
        Array.isArray(data.data) &&
        data.data[1] &&
        data.data[1][0] === "choices" &&
        Array.isArray(data.data[1][1]) &&
        data.data[1][1][0] &&
        Array.isArray(data.data[1][1][0]) &&
        data.data[1][1][0][3] &&
        data.data[1][1][0][3][0] === "message" &&
        Array.isArray(data.data[1][1][0][3][1]) &&
        data.data[1][1][0][3][1][0] &&
        data.data[1][1][0][3][1][0][0] === "content") {

        return data.data[1][1][0][3][1][0][1];
      } else {
        console.log('Content not found in the expected structure');
        return null;
      }
    } catch (error) {
      console.error('Error parsing JSON:', error);
      return null;
    }
  }

  // extract the text content out (UNUSED)
  function extractContent1(jsonString) {
    try {
      // const data = JSON.parse(jsonString);
      const data = jsonString; // it is already an object...

      function findContent(obj) {
        if (Array.isArray(obj)) {
          for (let item of obj) {
            const result = findContent(item);
            if (result) return result;
          }
        } else if (typeof obj === 'object' && obj !== null) {
          if (obj.hasOwnProperty('content') && typeof obj.content === 'string') {
            return obj.content;
          }
          for (let key in obj) {
            const result = findContent(obj[key]);
            if (result) return result;
          }
        }
        return null;
      }

      const content = findContent(data);

      if (content) {
        return content;
      } else {
        console.log('Content not found in the JSON structure');
        return null;
      }
    } catch (error) {
      console.error('Error parsing JSON:', error);
      return null;
    }
  }

  // UNUSED
  const handleSendMessage1 = async () => {
    if (inputValue.trim() !== "") {
      setMessages([...messages, { text: inputValue, sender: "user" }]);
      setInputValue(""); // clear input value

      let respString = "Sorry, I don't understand. Please try again!";

      console.log('handleSendMessage state', state);
      const { staffId, subId, subSubId, clsStd, instId, clrId, oPlanId, yearId,
        chapName,
        subject_name,
        subject_sub_name,
        staff_name } = state || {};

      let promptStr = `Chapter: '${chapName}' \n`;
      promptStr += `Subject: '${subject_sub_name}' \n`;
      promptStr += `Question: '${inputValue}' \n\n`;
      promptStr += `Notes:\n`;
      promptStr += `- Be as brief and short as possible, you are a chat assistant.\n`;
      promptStr += `- Remember to provide context and relevant information.\n`;
      promptStr += `- Do not go beyond 200 letters in your response.\n`;
      promptStr += `- For math or science subject, give HTML unicode formulas and never give LaTeX style formatting.`;
      let sysPromptStr = `You are an expert Grade ${clsStd} CBSE ${subject_sub_name} Teacher.`;

      console.log('handleSendMessage promptStr', promptStr);
      console.log('handleSendMessage sysPromptStr', sysPromptStr);

      // generate AI response
      try {
        const response = await AIASSISTAPI.generateContentAuto(promptStr);
        // const resp1 = await response.json();
        console.log('handleSendMessage response', response);

        // parse the response to pull out the message
        // respString = extractOpenAIContent(response.data);
        respString = findNestedValue(response.data, "content");
        console.log('handleSendMessage respString', respString);
        setMessages((prevMessages) => [
          ...prevMessages,
          { text: respString, sender: "ai" },
        ]);
      } catch (error) {
        console.error('handleSendMessage error', error);

        // Simulate AI response
        setTimeout(() => {
          setMessages((prevMessages) => [
            ...prevMessages,
            // { text: `AI response to: "${inputValue}"`, sender: "ai" },
            { text: `Sorry, I don't understand. Please try again!`, sender: "ai" },
          ]);
        }, 1000);
      }


    }
  };

  const handleGenerateSummary = async () => {
    setInputValue("Generate Summary");

    setTimeout(() => {
      handleSendMessage("Generate Summary");
    }, 100);
    
  }

  const handleGenerateQuestions = async () => {
    setInputValue("Generate Questions");

    setTimeout(() => {
      handleSendMessage("Generate Questions");
    }, 100);
  }

  const handleSendMessage = async (inputValue2) => {
    let inputValue3 = inputValue;

    if(!inputValue3 && inputValue2) {
      inputValue3 = inputValue2;
    }

    if (inputValue3.trim() !== "") {
      // Add user message
      setMessages([...messages, { text: inputValue3, sender: "user" }]);
      setInputValue(""); // clear input value

      // Add placeholder message for AI response
      const tempMessage = { text: "Generating...", sender: "ai", id: Date.now() };
      setMessages((prevMessages) => [
        ...prevMessages,
        tempMessage
      ]);

      let respString = "Sorry, I don't understand. Please try again!";

      console.log('handleSendMessage state', state);
      const { staffId, subId, subSubId, clsStd, instId, clrId, oPlanId, yearId,
        chapName,
        subject_name,
        subject_sub_name,
        staff_name } = state || {};

      let promptStr = `Chapter: '${chapName}' \n`;
      promptStr += `Subject: '${subject_sub_name}' \n`;
      promptStr += `Question: '${inputValue3}' \n\n`;
      promptStr += `Notes:\n`;
      promptStr += `- Be as brief and short as possible, you are a chat assistant.\n`;
      promptStr += `- Remember to provide context and relevant information.\n`;
      promptStr += `- Do not go beyond 200 letters in your response.\n`;
      promptStr += `- Do not use markdown or LaTeX formatting.\n`;
      promptStr += `- For math or science subject, give plain text with HTML Unicode formulas, and avoid LaTeX formatting entirely.\n`;
      promptStr += `- Ensure the response is suitable for direct display in a chat interface.`;

      let sysPromptStr = `You are an expert Grade ${clsStd} CBSE ${subject_sub_name} Teacher.`;

      console.log('handleSendMessage promptStr', promptStr);
      console.log('handleSendMessage sysPromptStr', sysPromptStr);

      // generate AI response
      try {
        const response = await AIASSISTAPI.generateContentAuto(promptStr);
        console.log('handleSendMessage response', response);

        // Parse the response to pull out the message
        respString = findNestedValue(response.data, "content");
        console.log('handleSendMessage respString', respString);

        // update for HTML
        // respString = respString.replace(/\n/g, '<br />');

        // Replace the "Generating..." message with the actual response
        setMessages((prevMessages) =>
          prevMessages.map((msg) =>
            msg.id === tempMessage.id ? { ...msg, text: respString } : msg
          )
        );
      } catch (error) {
        console.error('handleSendMessage error', error);

        // Replace the "Generating..." message with an error message
        setMessages((prevMessages) =>
          prevMessages.map((msg) =>
            msg.id === tempMessage.id ? { ...msg, text: "Sorry, I don't understand. Please try again!" } : msg
          )
        );
      }
    } else {
      console.log('handleSendMessage: Input value is empty');
    }
  };


  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSendMessage();
    }
  };

  useEffect(() => {
    // Simulate loading chap data
    // setTimeout(() => {
    //   setChapName("Chapter 1: The First Page");
    // }, 1000);

    console.log('CopilotUI plan', plan);

    // extract the data from props
    const { data, names_dict } = plan;

    console.log(`CopilotUI data`, data);
    console.log(`CopilotUI names_dict`, names_dict);

    const { chapter_id, chapter_index, icc_day, pub_chap_name } = names_dict;
    setChapId(chapter_id);
    setChapName(pub_chap_name);
    setLessonPlan(data);

    // go to bottom of box
    // Ensure that we scroll to the bottom only after the messages update
    scrollToBottom();
  }, [messages]);

  // text with breaks
  const formatTextWithLineBreaks = (text) => {
    return text.split('\n').map((line, index) => (
      <span key={index}>
        {line}
        <br />
      </span>
    ));
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        border: "1px solid #e0e0e0",
        borderRadius: "12px",
        overflow: "hidden",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        backgroundColor: "#fff",
      }}
    >
      {/* Header */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "10px",
          backgroundColor: "#F5F5F5",
        }}
      >
        <IconButton onClick={props.onClose}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="body1" sx={{ fontWeight: "600" }}>
          Vidya
        </Typography>
        <IconButton>
          {/* <ChatIcon /> */}
          <img
            // src="assets/VidyaLogo.gif"
            // src="https://firebasestorage.googleapis.com/v0/b/classess-501e6.appspot.com/o/images%2FVidyaLogo.gif?alt=media&token=f168d84f-0cb1-47f7-80d1-3df31b243b08"
            src="https://firebasestorage.googleapis.com/v0/b/classess-501e6.appspot.com/o/icons%2FVidya_BOT_Logo.gif?alt=media&token=6adaba1e-5ced-43ba-83b3-2b287b3c05d7"
            alt="Vidya"
            style={{ height: "24px", marginBottom: "24px", padding: 0, margin: 0 }}
          />
        </IconButton>
      </Box>

      {/* Main content */}
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          padding: "20px",
          overflowY: "auto",
          overflowX: "hidden",
          scrollbarWidth: 'thin'
        }}
      >
        {messages.length === 0 ? (
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              // src="assets/VidyaLogo.gif"
              // src="https://firebasestorage.googleapis.com/v0/b/classess-501e6.appspot.com/o/images%2FVidyaLogo.gif?alt=media&token=f168d84f-0cb1-47f7-80d1-3df31b243b08"
              src="https://firebasestorage.googleapis.com/v0/b/classess-501e6.appspot.com/o/icons%2FVidya_BOT_Logo.gif?alt=media&token=6adaba1e-5ced-43ba-83b3-2b287b3c05d7"
              alt="Vidya"
              style={{ height: "96px", marginBottom: "15px" }}
            />
            <Typography
              variant="body2"
              sx={{ color: "gray", marginBottom: "20px" }}
            >
              Welcome to Vidya {state.staff_name}! Start by asking a question about the chapter you're
              reading.
            </Typography>

            <Button
              variant="outlined"
              fullWidth
              startIcon={<SummarizeIcon />}
              sx={{
                marginBottom: "10px",
                borderRadius: "8px",
                textTransform: "none",
              }}
              onClick={handleGenerateSummary}
            >
              Generate summary
            </Button>
            <Button
              variant="outlined"
              fullWidth
              startIcon={<SummarizeIcon />}
              sx={{ borderRadius: "8px", textTransform: "none" }}
              onClick={handleGenerateQuestions}
            >
              Suggest questions about this chapter
            </Button>
          </Box>
        ) : (
          <List>
            {messages.map((message, index) => (
              <ListItem
                key={index}
                alignItems="flex-start"
                sx={{
                  flexDirection:
                    message.sender === "user" ? "row-reverse" : "row",
                }}
              >
                <Paper
                  elevation={1}
                  sx={{
                    p: 1,
                    maxWidth: "70%",
                    bgcolor: message.sender === "user" ? "#E3F2FD" : "#F5F5F5",
                  }}
                >
                  <ListItemText primary={formatTextWithLineBreaks(message.text)} />
                </Paper>
              </ListItem>
            ))}
          </List>
        )}
        {/* The reference to the bottom of the message list */}
        <div ref={messagesEndRef} />
      </Box>

      {/* Footer */}
      <Box
        sx={{
          padding: "10px",
          backgroundColor: "#F5F5F5",
        }}
      >
        <TextField
          fullWidth
          value={inputValue}
          onChange={handleInputChange}
          onKeyPress={handleKeyPress}
          placeholder='       Ask me anything or type "@"'
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <ChatIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">

                {/* <IconButton>
                  <MicIcon />
                </IconButton> */}

                <IconButton onClick={handleSendMessage}>
                  <SendIcon />
                </IconButton>
              </InputAdornment>
            ),
            sx: { borderRadius: "50px", backgroundColor: "#fff" },
          }}
        />
      </Box>
    </Box>
  );
};

const Vidya = ({ open, onClose, plan, state }) => {
  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { width: 400 },
      }}
    >
      <CopilotUI onClose={onClose}
        plan={plan}
        state={state}
      />

      {/* <Box sx={{ p: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Typography variant="h6">Sider: ChatGPT Sidebar + GPT-4o, Cl...</Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>


        <Box sx={{ bgcolor: '#f0f0f0', p: 2, borderRadius: 1, mb: 2 }}>
          <Typography variant="body2" sx={{ mb: 1 }}>
            Power up your learning with AI: Save 10% on all plans, plus an explosive discount on Unlimited!
          </Typography>
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Button variant="contained" color="primary" size="small">
              Upgrade Now
            </Button>
            <Button variant="text" size="small">
              Dismiss
            </Button>
          </Box>
        </Box>


        <Typography variant="h5" sx={{ mb: 2 }}>
          How can I assist you today?
        </Typography>


        <List>
          {[
            'Tell me something about the Big Bang so that I can explain it to my 5-year-old child',
            "Please provide me with 10 gift ideas for my friend's birthday",
            'Generate five catchy titles for my writing about the use case of ChatGPT'
          ].map((text, index) => (
            <ListItem key={index} disablePadding>
              <ListItemText 
                primary={text} 
                sx={{ 
                  '& .MuiListItemText-primary': { 
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }
                }}
              />
              <ArrowForwardIcon />
            </ListItem>
          ))}
        </List>


        <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', gap: 1 }}>
          <Box 
            component="img" 
            src="/api/placeholder/24/24"
            alt="AI Assistant"
            sx={{ width: 24, height: 24, borderRadius: '50%' }}
          />
          <Typography variant="body2">Sider, best ChatGPT b</Typography>
          <Button variant="outlined" size="small">
            Summarize
          </Button>
        </Box>


        <TextField
          fullWidth
          variant="outlined"
          placeholder="Type a message..."
          sx={{ mt: 2 }}
        />
      </Box> */}
    </Drawer>
  );
};

export default Vidya;
