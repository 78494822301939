import React, { useState, useEffect, useCallback, useRef } from "react";
import { IconButton, Box } from "@mui/material";
import Vidya from "./Vidya";
import { Tooltip } from "../../../packages/excalidraw/components/Tooltip";

interface VidyaButtonProps {
  plan: any;
  state: any;
  size: number;
  onMouseDown: (e: React.MouseEvent) => void;
  onClick: () => void;
}

const VidyaButton: React.FC<VidyaButtonProps> = ({
  plan,
  state,
  size,
  onMouseDown,
  onClick,
}) => {
  const imageUrl =
    "https://firebasestorage.googleapis.com/v0/b/classess-501e6.appspot.com/o/icons%2FVidya_BOT_Logo.gif?alt=media&token=6adaba1e-5ced-43ba-83b3-2b287b3c05d7";

  return (
    <>
      <IconButton
        onMouseDown={onMouseDown}
        onClick={onClick}
        sx={{
          backgroundColor: "white",
          boxShadow: 3,
          padding: 0,
          "&:hover": {
            backgroundColor: "white",
          },
        }}
      >
        <img
          src={imageUrl}
          alt="Movable Button Icon"
          style={{
            width: size,
            height: size,
            borderRadius: "50%",
            objectFit: "cover",
            pointerEvents: "none",
          }}
        />
      </IconButton>
    </>
  );
};

interface MovableImageButtonProps {
  initialPosition?: number;
  size?: number;
  state: any;
  plan: any;
}

const MovableImageButton: React.FC<MovableImageButtonProps> = ({
  initialPosition = 50,
  size = 40,
  state,
  plan,
}) => {
  const [position, setPosition] = useState<number>(initialPosition);
  const [isOpen, setIsOpen] = useState(false);
  const isDraggingRef = useRef<boolean>(false);
  const startPosRef = useRef<{ x: number; y: number }>({ x: 0, y: 0 });
  const startButtonPosRef = useRef<number>(0);
  const dragDistanceRef = useRef<number>(0);

  const handleMouseDown = useCallback(
    (e: React.MouseEvent) => {
      startPosRef.current = { x: e.clientX, y: e.clientY };
      startButtonPosRef.current = position;
      isDraggingRef.current = true;
      dragDistanceRef.current = 0;
      e.preventDefault();
    },
    [position],
  );

  const handleMouseMove = useCallback((e: MouseEvent) => {
    if (isDraggingRef.current) {
      const deltaY = e.clientY - startPosRef.current.y;
      dragDistanceRef.current += Math.abs(deltaY);
      const deltaPosition = (deltaY / window.innerHeight) * 100;
      const newPosition = Math.max(
        0,
        Math.min(startButtonPosRef.current + deltaPosition, 100),
      );
      setPosition(newPosition);
    }
  }, []);

  const handleMouseUp = useCallback((e: MouseEvent) => {
    isDraggingRef.current = false;
  }, []);

  const handleClick = useCallback(() => {
    if (dragDistanceRef.current < 5) {
      // Threshold to determine if it's a click
      setIsOpen(true);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [handleMouseMove, handleMouseUp]);

  return (
    <Box
      sx={{
        position: "fixed",
        right: 16,
        top: `${position}%`,
        transform: "translateY(-50%)",
        zIndex: 1000,
        cursor: isDraggingRef.current ? "grabbing" : "grab",
      }}
    >
      <Tooltip label="Open Chat">
        <VidyaButton
          onMouseDown={handleMouseDown}
          onClick={handleClick}
          state={state}
          plan={plan}
          size={size}
        />
      </Tooltip>
      <Vidya
        open={isOpen}
        onClose={() => setIsOpen(false)}
        plan={plan}
        state={state}
      />
    </Box>
  );
};

export default MovableImageButton;
