/* eslint-disable no-console */
import React from "react";
import { Button, Footer } from "../../packages/excalidraw/index";
import { EncryptedIcon } from "./EncryptedIcon";
import { ExcalidrawPlusAppLink } from "./ExcalidrawPlusAppLink";
import { isExcalidrawPlusSignedUser } from "../app_constants";
import { DebugFooter, isVisualDebuggerEnabled } from "./DebugCanvas";
import { Excalidraw, useHandleLibrary } from "@excalidraw/excalidraw";
import MovableImageButton from "../src/frags/MovableImageButton";

const imageUrl = "https://firebasestorage.googleapis.com/v0/b/classess-501e6.appspot.com/o/icons%2FVidya_BOT_Logo.gif?alt=media&token=6adaba1e-5ced-43ba-83b3-2b287b3c05d7";
const imageUrl2 = "https://classess.com/img/ss1.png";
export const AppFooter = React.memo(
  ({
    onChange,
    state,
    plan,
    handleAddImage,
  }: {
    onChange: () => void;
    state: any;
    plan: any;
    handleAddImage: (imageUrl: any) => void;
  }) => {
    console.log("AppFooter plan", plan);

    const onAddImage = () => {
      if (imageUrl2) {
        handleAddImage(imageUrl2);
        // setImageUrl('');  // Clear the input after adding
      }
    }
    
    return (
      <Footer>
        <div
          style={{
            display: "flex",
            gap: ".5rem",
            alignItems: "center",
          }}
        >
          {isVisualDebuggerEnabled() && <DebugFooter onChange={onChange} />}
          {isExcalidrawPlusSignedUser ? (
            <ExcalidrawPlusAppLink />
          ) : (
            <EncryptedIcon state={state} plan={plan} />
          )}
{/*           
          <Button onClick={onAddImage} onSelect={function () {
            throw new Error("Function not implemented.");
          } } children={"Add Image"}></Button>
         */}

            <MovableImageButton 
              
              initialPosition={30} 
              size={50} // Optional: adjust size as needed
              state={state}
              plan={plan}
            />
        </div>
        
      </Footer>
    );
  },
);
