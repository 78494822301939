import React, { useState, useEffect } from "react";
import { CircularProgress, Box, Typography, } from "@mui/material";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid,Cell , Tooltip, ResponsiveContainer } from 'recharts';
import "chart.js/auto";
import "./QuizComponent.css";

const TIME_SECONDS = 30;

interface QuizComponentProps {
  onQuizComplete?: () => void;
  onShowPodium?: () => void;
}

interface QuizResult {
  data: {
    students: {
      selected_option: any;
      student_id: number;
      student_name: string;
      student_image: string;
      result: string;
    }[];
    correct: string[];
    question: {
      quiz_question_id: number;
      quiz_id: number;
      question_text: string;
    };
  };
}

interface QuizOption {
  quiz_question_option_id: number;
  quiz_question_id: number;
  question_option_text: string;
  question_option_url: string;
  question_option_index: number;
  question_option_correct: number;
  created_date: string;
  timestamp: string;
}

interface Quiz {
  quiz_question_id: number;
  quiz_id: number;
  question_text: string;
  question_url: string;
  question_topic_text: string;
  question_pts: number;
  question_status: number;
  question_time: string;
  student_attmp: number;
  student_correct: number;
  created_date: string;
  timestamp: string;
  staff_lesson_id: number;
  staff_id: number;
  classroom_id: number;
  subject_id: number;
  subject_sub_id: number;
  chapter_id: number;
  quiz_title: string;
  scheduled_date: string;
  quiz_pub: number;
  quiz_ended: number;
}

interface QuizData {
  quiz: Quiz;
  options: QuizOption[];
}

const QuizComponent: React.FC<QuizComponentProps> = ({ onShowPodium }) => {
  const [quizData, setQuizData] = useState<QuizData[]>([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [showOptions, setShowOptions] = useState(true);
  const [showGraph, setShowGraph] = useState(false);
  const [showWaitingAnimation, setShowWaitingAnimation] = useState(false);
  const [correctOptionIndex, setCorrectOptionIndex] = useState(null);
  const [timeLeft, setTimeLeft] = useState(TIME_SECONDS);
  const [questionVisible, setQuestionVisible] = useState(true);
  const [loading, setLoading] = useState(true);
  const [correctOptionText, setCorrectOptionText] = useState(null);
  const [currentQuestionId, setCurrentQuestionId] = useState<number | null>(
    null,
  );
  const [quizResults, setQuizResults] = useState<QuizResult | null>(null);

  const clrId5 = localStorage.getItem("co.vidya.clrid");
  console.log('QuizComponent clrId', clrId5);
  useEffect(() => {
    const eventSource = new EventSource(
      "https://api.classess.com/quiz/get-latest-quiz-sse-day/6547/1144/1",
    );
  
    // const formatToISO = (date) => {
    //   return date.toISOString();
    // };
  
    // const isWithinOneMinute = (timestamp1, timestamp2) => {
    //   const date1 = new Date(timestamp1);
    //   const date2 = new Date(timestamp2);
      
    //   // Add 5.5 hours (in milliseconds) to timestamp1
      
    //   const differenceInMs = Math.abs(date1.getTime() - date2.getTime());
    //   const differenceInMinutes = differenceInMs / (1000 * 60);
      
    //   return differenceInMinutes <= 5;
    // };
  
    eventSource.onmessage = (event) => {
      try {
        
        const parsedData = JSON.parse(event.data);
        console.log("parsedData", parsedData)
        if (parsedData.quiz) {
          // const questionTimestamp = parsedData.quiz.timestamp;
          // const currentTimestamp = formatToISO(new Date());
          
            const newQuestionId = parsedData.quiz.quiz_question_id;

            if (parsedData.quiz.question_time === "00:00:00" && showWaitingAnimation) {
              setShowWaitingAnimation(false);
              setShowGraph(true);
            }

            setQuizData((prevData) => {
              const exists = prevData.some(
                (q) => q.quiz.quiz_question_id === newQuestionId,
              );
              if (!exists) {
                if (
                  currentQuestionId !== null &&
                  newQuestionId !== currentQuestionId
                ) {
                  setTimeout(() => {
                    setCurrentQuestionIndex(prevData.length);
                    resetQuizState();
                  }, 1000);
                }
                return [...prevData, parsedData];
              }
              return prevData;
            });
  
            setCurrentQuestionId(newQuestionId);
            setLoading(false);
         
        } else {
          onShowPodium();
        }
      } catch (error) {
        console.error("Error parsing SSE data:", error);
      }
    };
  
    eventSource.onerror = (error) => {
      console.error("SSE Error:", error);
      eventSource.close();
    };
  
    return () => {
      eventSource.close();
    };
  }, [currentQuestionId, showWaitingAnimation]);

  const resetQuizState = () => {
    setShowOptions(false);
    setShowGraph(false);
    setShowWaitingAnimation(false);
    setCorrectOptionIndex(null);
    setTimeLeft(TIME_SECONDS);
    setQuestionVisible(true);
    setTimeout(() => setShowOptions(true), 5000);
  };

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;
    
    if (showOptions && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prev) => {
          if (prev <= 1 ) {
            if (timer) clearInterval(timer);
            setShowOptions(false);
            setQuestionVisible(false);
            setShowWaitingAnimation(true);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }
  
    return () => {
      if (timer) clearInterval(timer);
    };
  }, [showOptions]);

  const optionLetters = ['A', 'B', 'C', 'D'];
  useEffect(() => {
    if (quizData[currentQuestionIndex]?.options) {
      const correctOptionIndex = quizData[currentQuestionIndex].options.findIndex(
        (option) => option.question_option_correct === 1
      );
      if (correctOptionIndex !== -1) {
        setCorrectOptionIndex(correctOptionIndex);
        const correctOption = quizData[currentQuestionIndex].options[correctOptionIndex];
        const optionLetter = optionLetters[correctOptionIndex];
        setCorrectOptionText(`${optionLetter}. ${correctOption.question_option_text}`);
      }
    }
  }, [quizData, currentQuestionIndex]);


  useEffect(() => {
    const fetchQuizResults = async () => {
      if (showGraph && quizData[currentQuestionIndex]?.quiz) {
        try {
          const response = await fetch(
            `https://api.classess.com/quiz/get-quiz-results/${quizData[currentQuestionIndex].quiz.quiz_id}`,
          );
          const data = await response.json();  
          setQuizResults(data);
        } catch (error) {
          console.error("Error fetching quiz results:", error);
        }
      }
    };
  
    fetchQuizResults();
  }, [showGraph, quizData, currentQuestionIndex]);

  const getChartData = () => {
    if (!quizData[currentQuestionIndex] || !quizResults?.data?.students) {
      const options = quizData[currentQuestionIndex]?.options || [];
      return options.map((option, index) => {
        const optionLetter = String.fromCharCode(65 + index); 
        return {
          name: optionLetter,
          value: 0,
          text: option.question_option_text,
          isCorrect: option.question_option_correct === 1
        };
      });
    }
  
    const optionCounts = {
      'A': 0,
      'B': 0,
      'C': 0,
      'D': 0 
    };

    quizResults.data.students.forEach(student => {
      if (student.selected_option in optionCounts) {
        optionCounts[student.selected_option]++;
      }
    });
console.log("",quizResults)

     // Create chart data array
     const options = quizData[currentQuestionIndex].options;
     return options.map((option, index) => {
       const optionLetter = String.fromCharCode(65 + index); 
       return {
         name: `Option ${optionLetter}`,
         value: optionCounts[optionLetter] || 0,
         text: option.question_option_text,
         isCorrect: option.question_option_correct === 1
       };
     });
  };

  const currentQuestion = quizData[currentQuestionIndex]?.quiz || null;
  const hasValidQuestion = currentQuestion && currentQuestion.question_text;


  return (
    <div className="QuizContainer">
      <h1 className="QuizHeading">POP QUIZ</h1>
      <div className="GradientLine"></div>

      <div className="QuestionSection">
        {showOptions && questionVisible && hasValidQuestion && (
          <div className="TimerContainer">
            <div className="TimerCircle">
              <svg className="TimerCircleSvg">
                <circle className="GraySvgCircle" />
                <circle className="BlueSvgCircle" />
              </svg>
              <span className="timer-text">{timeLeft}s</span>
            </div>
          </div>
        )}
        {questionVisible && hasValidQuestion && (
          <h5 className="QuestionText">
            {currentQuestion
              ? currentQuestion.question_text
              : "No question available"}
          </h5>
        )}
      </div>
      
      {showWaitingAnimation && (
        <div className="waiting-animation">
          <CircularProgress />
          <Typography variant="h6" className="waiting-text">
           Waiting for the teacher to complete the Scanning..
          </Typography>
        </div>
      )}

      {showOptions && questionVisible && quizData[currentQuestionIndex] && hasValidQuestion && (
        <div className="OptionsSection">
          <ul>
            {[...quizData[currentQuestionIndex].options]
              .map((option, index) => (
                <li
                  key={option.quiz_question_option_id}
                  className={`option option-${index}`}
                >
                  <span className="option-letter">
                    {String.fromCharCode(65 + index)}
                  </span>
                  <span className="option-text">
                    {option.question_option_text}
                  </span>
                </li>
              ))}
          </ul>
        </div>
      )}

{showGraph && quizResults && getChartData() && (
        <div className="GraphSection" style={{ width: '100%', height: 400 }}>
          <ResponsiveContainer>
            <BarChart
              data={getChartData()}
              margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name"/>
              <YAxis />
              <Bar dataKey="value" radius={[4, 4, 0, 0]}>
                {getChartData().map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={entry.isCorrect ? '#4CAF50' : '#FF6B6B'} />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
          
        </div>
    )}
    </div>
    
  );
};
export default QuizComponent;
